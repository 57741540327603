import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MAIN_CONTENT_EL_ID } from '../../core/constants/global';
import { PlanMainInformation } from '../../../features/plans/services/types';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  showMainNav$ = new BehaviorSubject<boolean>(false);
  showBreadcrumb$ = new BehaviorSubject<boolean>(false);
  showPlanPageHeader$ = new BehaviorSubject<boolean>(false);
  addPagePadding$ = new BehaviorSubject<boolean>(false);
  showPlanInformationHeader$ = new BehaviorSubject<boolean>(false);
  planInformation$ = new BehaviorSubject<PlanMainInformation | null>(null);
  showEditFormLayout$ = new BehaviorSubject<boolean>(false);

  getScrollOffset() {
    return document.getElementById(MAIN_CONTENT_EL_ID)?.scrollTop || 0;
  }

  setScrollOffsetY(offsetY: number) {
    const el = document.getElementById(MAIN_CONTENT_EL_ID);

    if (!el) return;

    document.getElementById(MAIN_CONTENT_EL_ID)?.scroll(el.offsetLeft, offsetY);
  }
}
