export enum ToastTypes {
  Info = 'Info',
  Success = 'Success',
  Warning = 'Warning',
  Error = 'Error',
}

export interface ToastData {
  type: ToastTypes;
  needsAction?: boolean;
  showClose?: boolean;
  duration?: number;
  content?: string;
}
