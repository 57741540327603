import { Component } from '@angular/core';
import { LayoutService } from '../../services/layout/layout.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
})
export class PageComponent {
  showBreadcrumb$: Observable<boolean>;
  addPagePadding$: Observable<boolean>;

  constructor(layoutService: LayoutService) {
    this.showBreadcrumb$ = layoutService.showBreadcrumb$.asObservable();
    this.addPagePadding$ = layoutService.addPagePadding$.asObservable();
  }
}
