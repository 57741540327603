import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageComponent } from './core/page/page.component';
import { ROUTING_PATHS } from '../routing';
import { BREADCRUMBS } from '../breadcrumbs';
import { PermissionsGuard } from './shared/guards/permissions.guard';
import { ErrorsGuard } from './shared/guards/errors.guard';
import { NotFoundErrorComponent } from './core/errors/not-found-error/not-found-error.component';
import { PlanService } from '../features/plans/services/plan/plan.service';
import { PlanFormService } from '../features/plans/services/plan/plan-form.service';
import { DraftResolver } from '../features/plans/resolvers/draft.resolver';
import { PlanProfileResolver } from '../features/plans/resolvers/plan/plan-profile.resolver';
import { EmployerConfigurationResolver } from '../features/plans/resolvers/plan/employer-configuration.resolver';
import { MemberConfigurationResolver } from '../features/plans/resolvers/plan/member-configuration.resolver';
import { PensionConfigurationResolver } from '../features/plans/resolvers/plan/pension-configuration.resolver';
import { PlanResolver } from '../features/plans/resolvers/plan.resolver';

const routes: Routes = [
  { path: '', redirectTo: 'plans', pathMatch: 'full' },
  {
    path: ROUTING_PATHS.PLANS,
    component: PageComponent,
    providers: [
      PlanService,
      PlanFormService,
      PlanResolver,
      DraftResolver,
      PlanProfileResolver,
      EmployerConfigurationResolver,
      MemberConfigurationResolver,
      PensionConfigurationResolver,
    ],
    data: {
      breadcrumb: BREADCRUMBS.PLANS,
    },
    canActivate: [PermissionsGuard],
    loadChildren: () =>
      import('../features/plans/plans.module').then((m) => m.PlansModule),
  },
  {
    path: ROUTING_PATHS.ERRORS,
    canActivate: [ErrorsGuard],
    loadChildren: () =>
      import('./core/errors/errors.module').then((m) => m.ErrorsModule),
  },
  { path: '**', component: NotFoundErrorComponent, pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
