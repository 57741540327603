import { Injectable } from '@angular/core';
import {
  PlanDraftSection,
  PlanResponseUser,
  PlanResponseDto,
  NewMember,
  ClientPlanShortNames,
  ClientPlanShortNamesDto,
} from '../types';
import { environment } from '@epa-env/environment';
import { API_ROUTES } from '../../../../routing';
import { HttpClient } from '@angular/common/http';
import { PLAN_STATE } from '../../plan-item/constants';
import {
  BehaviorSubject,
  Observable,
  finalize,
  map,
  tap,
  throwError,
} from 'rxjs';
import { PlanFormService } from './plan-form.service';
import { NotificationToastService } from '@epa-shared/services/notification-toast/notification-toast.service';
import { ToastTypes } from '@epa-shared/components/notification-toast/types';
import { PlanConfigurationSection, PlanSections } from '../constants';
import { PlanRole, User } from '../../plan-profile/types';
import { ItemListResponseDto } from '@epa-shared/types/global';
import { catchError } from 'rxjs/operators';

@Injectable()
export class PlanService {
  private readonly _loading$ = new BehaviorSubject<boolean>(false);
  loading$ = this._loading$.asObservable();

  constructor(
    private http: HttpClient,
    private planFormService: PlanFormService,
    private toastService: NotificationToastService,
  ) {}

  createDraft(planType: PLAN_STATE) {
    this.setLoading(true);

    return this.http
      .post<PlanDraftSection>(
        `${environment.apiUrl}${API_ROUTES.PLAN_DRAFTS}`,
        { planState: planType },
      )
      .pipe(finalize(() => this.setLoading(false)));
  }

  updateDraft(requestData: Object, calledFrom: PlanSections) {
    this.setLoading(true);

    return this.http
      .put<PlanDraftSection>(
        `${environment.apiUrl}${API_ROUTES.PLAN_DRAFTS}/${this.planFormService.formGroup.value.id}`,
        requestData,
      )
      .pipe(
        tap((data) => {
          this.toastService.showToast({
            type: ToastTypes.Success,
            content: `${calledFrom} successfully saved`,
            showClose: true,
          });
          this.planFormService.setMainFormData(data.item);
        }),
        catchError((err) => {
          this.toastService.showToast({
            type: ToastTypes.Error,
            showClose: true,
          });

          return throwError(() => err);
        }),
        finalize(() => this.setLoading(false)),
      );
  }

  completeDraft() {
    this.setLoading(true);

    return this.http
      .put<any>(
        `${environment.apiUrl}${API_ROUTES.PLAN_DRAFTS}/${this.planFormService.formGroup.value.id}/complete`,
        null,
      )
      .pipe(
        finalize(() => {
          this.setLoading(false);
        }),
      );
  }

  getDraft(id: number) {
    this.setLoading(true);

    return this.http
      .get<PlanDraftSection>(
        `${environment.apiUrl}${API_ROUTES.PLAN_DRAFTS}/${id}`,
      )
      .pipe(finalize(() => this.setLoading(false)));
  }

  getPlanSection(planId: number, section: PlanConfigurationSection) {
    this.setLoading(true);

    return this.http
      .get<PlanDraftSection>(
        `${environment.apiUrl}${API_ROUTES.PLAN_DRAFTS}/${planId}/${section}`,
      )
      .pipe(finalize(() => this.setLoading(false)));
  }

  getPlan(planId: string) {
    this.setLoading(true);

    return this.http
      .get<PlanResponseDto>(
        `${environment.apiUrl}${API_ROUTES.PLANS}/${planId}`,
      )
      .pipe(
        finalize(() => this.setLoading(false)),
        map((res) => res.item),
      );
  }

  getPlanRoles() {
    return this.http
      .get<ItemListResponseDto<PlanRole>>(
        `${environment.apiUrl}${API_ROUTES.PLAN_ROLES}`,
      )
      .pipe(map((res) => res.items));
  }

  // TODO move to users service
  getUsers() {
    return this.http
      .get<ItemListResponseDto<User>>(
        `${environment.apiUrl}${API_ROUTES.USERS}`,
      )
      .pipe(map((res) => res.items));
  }

  getPlanUsers(id: string) {
    return this.http
      .get<ItemListResponseDto<PlanResponseUser>>(
        `${environment.apiUrl}${API_ROUTES.PLANS}/${id}/users`,
      )
      .pipe(map((res) => res.items));
  }

  saveUsers(id: string, users: any) {
    this.setLoading(true);
    return this.http
      .put<any>(`${environment.apiUrl}${API_ROUTES.PLANS}/${id}/users`, {
        users: users.map((u: any) => ({
          id: u.id,
          roleIds: u.roles,
        })),
      })
      .pipe(
        tap(() => {
          this.toastService.showToast({
            type: ToastTypes.Success,
            content: 'Users successfully saved',
            showClose: true,
          });
        }),
        catchError((err) => {
          this.toastService.showToast({
            type: ToastTypes.Error,
            showClose: true,
          });
          return throwError(() => err);
        }),
        finalize(() => this.setLoading(false)),
      );
  }

  addMember(requestData: NewMember) {
    return this.http.put<NewMember>(
      `${environment.apiUrl}${API_ROUTES.PLANS}/${this.planFormService.formGroup.value.id}/data/member`,
      requestData,
    );
  }

  validateClientPlanShortNames(
    params: ClientPlanShortNames,
  ): Observable<ClientPlanShortNamesDto> {
    let paramsQuery = `/${params.clientShortName}/${params.planShortName}/${params.planId}`;

    const url = `${environment.apiUrl}${API_ROUTES.PLAN_DRAFTS}/validate-client-plan-shortnames${paramsQuery}`;
    return this.http.get<ClientPlanShortNamesDto>(url);
  }

  private setLoading(loading: boolean) {
    this._loading$.next(loading);
  }
}
