import { Component } from '@angular/core';
import { ROUTING_PATHS } from '../../../../routing';
import { AuthService } from '../../../services/auth/auth.service';
import { Observable, tap } from 'rxjs';
import { User } from '../../../services/auth/types';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { PlanMainInformation } from '../../../../features/plans/services/types';
import { LayoutService } from '../../../services/layout/layout.service';
import { PERMISSIONS } from '../../../shared/utils/permissions';

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss'],
})
export class MainNavComponent {
  ROUTING_PATHS = ROUTING_PATHS;
  PERMISSIONS = PERMISSIONS;
  user$?: Observable<User | undefined>;
  imageUrl?: SafeResourceUrl;
  planInformation$?: Observable<PlanMainInformation | null>;

  constructor(
    private auth: AuthService,
    private sanitizer: DomSanitizer,
    layoutService: LayoutService,
  ) {
    this.user$ = auth.user$
      .asObservable()
      .pipe(tap((user) => this.setUserImage(user?.image)));

    this.planInformation$ = layoutService.planInformation$.asObservable();
  }

  setUserImage(base64?: string) {
    if (!base64) return;

    this.imageUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      'data:image/*;base64,' + base64,
    );
  }

  logout() {
    this.auth.logout();
  }
}
