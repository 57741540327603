import { Injectable } from '@angular/core';
import { NotificationToastComponent } from '../../components/notification-toast/notification-toast.component';
import { ToastData } from '../../components/notification-toast/types';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DEFAULT_TOAST_DURATION_MS } from './constants';

@Injectable({
  providedIn: 'root',
})
export class NotificationToastService {
  constructor(private _snackBar: MatSnackBar) {}

  showToast(data: ToastData): void {
    this._snackBar.openFromComponent(NotificationToastComponent, {
      duration: data.needsAction
        ? undefined
        : data.duration ?? DEFAULT_TOAST_DURATION_MS,
      data: data,
    });
  }

  hideToast(): void {
    this._snackBar.dismiss();
  }
}
