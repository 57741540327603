import { DropdownOption } from '@epa-shared/components/fields/dropdown-field/types';

export enum AvailableEnums {
  AddressStatus = 'AddressStatus',
  BeneficiaryRelationship = 'BeneficiaryRelationship',
  BenefitType = 'BenefitType',
  BuybackPaymentType = 'BuybackPaymentType',
  BuybackPurchaseStatus = 'BuybackPurchaseStatus',
  CallLogReason = 'CallLogReason',
  CallLogType = 'CallLogType',
  ContributionPlanType = 'ContributionPlanType',
  ContributionType = 'ContributionType',
  Country = 'Country',
  CrmLocation = 'CrmLocation',
  DistributionMethod = 'DistributionMethod',
  DocumentType = 'DocumentType',
  EarningType = 'EarningType',
  FlexBenefitOption = 'FlexBenefitOption',
  Gender = 'Gender',
  Jurisdiction = 'Jurisdiction',
  Language = 'Language',
  LeaveContributionStatus = 'LeaveContributionStatus',
  LeaveType = 'LeaveType',
  LumpSumPayType = 'LumpSumPayType',
  MailingStatus = 'MailingStatus',
  MaritalStatus = 'MaritalStatus',
  MbdSplitTiming = 'MbdSplitTiming',
  MbdSplitType = 'MbdSplitType',
  MemberStatus = 'MemberStatus',
  MemberType = 'MemberType',
  PensionChangeType = 'PensionChangeType',
  PensionPayType = 'PensionPayType',
  PensionRecipientType = 'PensionRecipientType',
  PensionStatus = 'PensionStatus',
  PensionStopReason = 'PensionStopReason',
  PersonFinancialAccountType = 'PersonFinancialAccountType',
  PlanElectionOptionType = 'PlanElectionOptionType',
  PlanFundedStatus = 'PlanFundedStatus',
  PlanType = 'PlanType',
  Pronoun = 'Pronoun',
  SpecialCaseUnlockingPayee = 'SpecialCaseUnlockingPayee',
  SpecialCaseUnlockingReason = 'SpecialCaseUnlockingReason',
  SpouseType = 'SpouseType',
  State = 'State',
  Title = 'Title',
  TransferType = 'TransferType',
  Quarter = 'Quarter',
  WorkflowBenefitActivity = 'WorkflowBenefitActivity',
  WorkflowRequestType = 'WorkflowRequestType',
}

export enum EnumStatus {
  NotFetched = 'NotFetched',
  Fetching = 'Fetching',
  Fetched = 'Fetched',
}

export const quartersData: DropdownOption[] = [
  { name: 'Q1 (January 1 - March 31)', id: 1 },
  { name: 'Q2 (April 1 - June 30)', id: 2 },
  { name: 'Q3 (July 1 - September 30)', id: 3 },
  { name: 'Q4 (October 1 - December 31)', id: 4 },
];

export const AVAILABLE_ENUMS_ARRAY: AvailableEnums[] = Object.keys(
  AvailableEnums,
).map((key) => key as AvailableEnums);
