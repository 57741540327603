<div
    [ngClass]="[
        selfContained ? 'preloader--self-contained' : 'preloader--absolute',
        fullScreen ? 'preloader--full-screen' : ''
    ]"
    class="preloader"
    data-testId="preloader"
>
    <mat-spinner [diameter]="diameter"></mat-spinner>
</div>
