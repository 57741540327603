<div
    class="app__connectivity-banner"
    [ngClass]="{
        'app__connectivity-banner--offline': this.isOffline
    }"
>
    You are currently offline, updates will not be saved. Please reconnect to
    save updates.
</div>
<div [ngClass]="(showMainNav$ | async) ? 'app--with-nav' : ''" class="app">
    <ng-container *ngIf="!redirecting; else redirectingView">
        <div class="app__product-header">
            <app-product-header></app-product-header>
        </div>
        <div class="app__main-nav">
            <app-main-nav></app-main-nav>
        </div>
        <div [id]="MAIN_CONTENT_EL_ID" class="app__content">
            <router-outlet></router-outlet>
        </div>
    </ng-container>
    <ng-template #redirectingView> </ng-template>
</div>

<ng-container *ngIf="showGlobalSpinner$ | async">
    <app-preloader [fullScreen]="true"></app-preloader>
</ng-container>
