import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { Subject } from 'rxjs';
import { ButtonTheme, themes } from './button.theme';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector -- exception for button and a elements to maintain useful native functionality
  selector: 'button[epa-button], a[epa-button]',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  standalone: true,
})
export class ButtonComponent implements OnInit, OnDestroy {
  @Input() type = '';
  @Input() theme: ButtonTheme = 'primary';
  @Input() excludeRootClass = false;
  @HostBinding('attr.disabled') buttonDisabled?: boolean;
  private unsubscribe$: Subject<void> = new Subject();

  constructor(public elRef: ElementRef, private renderer: Renderer2) {}

  private _disabled = false;

  @Input() set disabled(disabled: boolean) {
    this._disabled = disabled;
    this.buttonDisabled = disabled || undefined;
  }

  ngOnInit(): void {
    this.setStyling();
    this.setButtonType();
  }

  setStyling(): void {
    if (!this.excludeRootClass) {
      this.renderer.addClass(this.elRef.nativeElement, 'button');
    }
    themes[this.theme]
      .split(' ')
      .forEach((className: string) =>
        this.renderer.addClass(this.elRef.nativeElement, className),
      );
  }

  setButtonType(): void {
    if (this.elRef.nativeElement.tagName === 'BUTTON' && !this.type) {
      this.renderer.setAttribute(this.elRef.nativeElement, 'type', 'button');
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
