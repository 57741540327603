import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SUPPORT_EMAIL } from '../../../shared/utils/companyInformation';

@Component({
  selector: 'app-not-found-error',
  templateUrl: './not-found-error.component.html',
  styleUrls: ['./not-found-error.component.scss'],
})
export class NotFoundErrorComponent {
  SUPPORT_EMAIL = SUPPORT_EMAIL;

  constructor(private router: Router) {}

  goHome() {
    this.router.navigate(['/']);
  }
}
