import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbNavigationComponent } from './components/layout/breadcrumb-navigation/breadcrumb-navigation.component';
import { RouterModule } from '@angular/router';
import { ButtonComponent } from './components/button/button.component';
import { ContextMenuComponent } from './components/context-menu/context-menu.component';
import { MatMenuModule } from '@angular/material/menu';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { LoadingDirective } from './directives/loading/loading.directive';
import { NotificationToastComponent } from './components/notification-toast/notification-toast.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ModalComponent } from './components/modal/modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { PreloaderComponent } from './components/preloader/preloader.component';
import { PreventDoubleClickDirective } from './directives/prevent-double-click/prevent-double-click.directive';
import { SizeClassDirective } from './directives/size-class/size-class.directive';
import { PlanHeaderComponent } from '@epa-features/plans/plan/plan-header/plan-header.component';
import { PageNavigationHeaderComponent } from '@epa-shared/components/page-navigation-header/page-navigation-header.component';
import { BulletTextComponent } from './components/bullet-text/bullet-text.component';
import { NoItemsFoundMessageComponent } from '@epa-shared/components/no-items-found-message/no-items-found-message.component';
import { FileTypeIconComponent } from './components/file-type-icon/file-type-icon.component';

@NgModule({
  declarations: [
    BreadcrumbNavigationComponent,
    ContextMenuComponent,
    LoadingDirective,
    NotificationToastComponent,
    ModalComponent,
    PreventDoubleClickDirective,
    PlanHeaderComponent,
    PageNavigationHeaderComponent,
    BulletTextComponent,
    NoItemsFoundMessageComponent,
    FileTypeIconComponent,
  ],
  exports: [
    BreadcrumbNavigationComponent,
    ButtonComponent,
    ContextMenuComponent,
    LoadingDirective,
    PreventDoubleClickDirective,
    PlanHeaderComponent,
    PageNavigationHeaderComponent,
    BulletTextComponent,
    NoItemsFoundMessageComponent,
    FileTypeIconComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatMenuModule,
    AngularSvgIconModule,
    MatSnackBarModule,
    MatDialogModule,
    ButtonComponent,
    PreloaderComponent,
    SizeClassDirective,
  ],
})
export class SharedModule {}
