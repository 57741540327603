export const themeNames = [
  'primary',
  'secondary',
  'tertiary',
  'link',
  'link-red',
] as const;
export type ButtonTheme = (typeof themeNames)[number];

export const themes: {
  [K in ButtonTheme]: string;
} = {
  primary: 'button--primary',
  secondary: 'button--secondary',
  tertiary: 'button--tertiary',
  link: 'button--link',
  'link-red': 'button--link-red',
};
