import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ERRORS_MODULE_ROUTING_SEGMENTS } from '../../../routing';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  private errorRouteMapping: { [key: number | string]: string[] } = {
    401: [ERRORS_MODULE_ROUTING_SEGMENTS.UNAUTHORIZED],
    403: [ERRORS_MODULE_ROUTING_SEGMENTS.NO_PERMISSIONS],
    404: [ERRORS_MODULE_ROUTING_SEGMENTS.NOT_FOUND],
  };

  constructor(private router: Router) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        this.handleError(err.status);
        return throwError(() => err);
      }),
    );
  }

  private handleError(status: number) {
    if (status === 400) return;

    const DEFAULT_ERROR_ROUTE = [ERRORS_MODULE_ROUTING_SEGMENTS.UNHANDLED];
    const errorRoute = this.errorRouteMapping[status] || DEFAULT_ERROR_ROUTE;

    this.router.navigate(errorRoute, {
      state: {
        isFromRedirect: true,
      },
    });
  }
}
