import { Component, Input } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { NgIf, NgTemplateOutlet } from '@angular/common';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { A11yModule } from '@angular/cdk/a11y';

@Component({
  selector: 'app-main-nav-item',
  templateUrl: './main-nav-item.component.html',
  styleUrls: ['./main-nav-item.component.scss'],
  standalone: true,
  imports: [
    RouterLinkActive,
    NgTemplateOutlet,
    RouterLink,
    AngularSvgIconModule,
    A11yModule,
    NgIf,
  ],
})
export class MainNavItemComponent {
  @Input() link?: string;
  @Input() text = '';
  @Input() isActive = false;
  @Input() iconName?: string;
  @Input() markExact = true;
}
