export const PAGE_TITLE = {
  EPA: 'EPA',
  // Plans module
  PLANS: 'Plans',
  FAVOURITES: 'Favourites',
  ALL_ASSIGNED_PLANS: 'All Assigned Plans',
  DRAFTS: 'Drafts',
  CREATE_NEW_PLAN: 'Create a New Plan ',
  PLAN_PROFILE: 'Plan Profile',
  MEMBER_CONFIGURATION: 'Member Configuration',
  EMPLOYER_CONFIGURATION: 'Employer Configuration',
  PENSION_CONFIGURATION: 'Pension Configuration',
  USERS: 'Users',
  // Member module
  MEMBERS: 'Members',
  ADD_MEMBER: 'Add Member',
  PERSONAL: 'Personal',
  PERSON_DETAILS: 'Person Details',
  MEMBERSHIP: 'Membership',
  MEMBER_DETAILS: 'Member Details',
  SPOUSE: 'Spouse',
  SPOUSE_HISTORY: 'Spouse History',
  BENEFICIARIES: 'Beneficiaries',
  POWER_OF_ATTORNEY: 'Power of Attorney ',
  EARNINGS_AND_SERVICE: 'Earnings & Service',
  BUYBACK_HISTORY: 'Buyback History ',
  PENSION_BENEFITS: 'Pension Benefits',
  PENSION: 'Pension',
  GROUP_BENEFITS: 'Group Benefits',
  PAYOUTS: 'Payouts',
  PA_PSPA_PAR: 'PA/PSPA/PAR',
  COMMUNICATION_LOG: 'Communication Log',
  Documents: 'Documents',
  // Employers module
  EMPLOYERS: 'Employers',
  EDIT_EMPLOYER: 'Edit Employer',
  ADD_EMPLOYER: 'Add Employer',
  // Reports module
  DRAFT_REPORTS: 'Draft Reports',
  FINAL_REPORTS: 'Final Reports',
  ADD_REPORT: 'Add Report',
  ADD_QUARTERLY_PARS_REPORT: 'Add Quarterly PARs Report',
  EDIT_QUARTERLY_PARS_REPORT: 'Edit Quarterly PARs Report',
  // Templates module
  IMPORT_TEMPLATES: 'Import Templates',
  EXPORT_TEMPLATES: 'Export Templates',
  ADD_TEMPLATE: 'Add Template',
  ADD_IMPORT_TEMPLATE: 'Add Import Template',
  ADD_EXPORT_TEMPLATE: 'Add Export Template',
  EDIT_IMPORT_TEMPLATE: 'Edit Import Template',
  EDIT_EXPORT_TEMPLATE: 'Edit Export Template',
};
