import { Permission, PERMISSIONS } from './permissions';

export const hasMatchedPermissionByValue = (
  permissionsToCheck: string[],
  userPermissions: string[],
) => {
  return !!permissionsToCheck.filter((permission) =>
    userPermissions.includes(permission),
  ).length;
};

export const hasMatchedPermissionByName = (
  permissionsToCheck: Permission[],
  userPermissions: string[],
) => {
  return !!permissionsToCheck.filter((permissionName) =>
    userPermissions.includes(PERMISSIONS[permissionName]),
  ).length;
};
