export enum PlanConfigurationSection {
  Profile = 1,
  Employer = 2,
  Member = 3,
  Pension = 4,
}

export enum DraftRequiredBuckets {
  Profile = 'Profile',
}

export enum StaticPlanTableNames {
  EarningAndContributionHistory = 'EarningAndContributionHistory',
  Employer = 'Employer',
  EmployerContact = 'EmployerContact',
  Beneficiary = 'Beneficiary',
  BenefitAccrualHistory = 'BenefitAccrualHistory',
  BuybackHistory = 'BuybackHistory',
  BuybackContributionHistory = 'BuybackContributionHistory',
  CvHistory = 'CvHistory',
  DcAccountBalanceHistory = 'DcAccountBalanceHistory',
  FlexBenefitHistory = 'FlexBenefitHistory',
  GroupBenefitHistory = 'GroupBenefitHistory',
  LeaveHistory = 'LeaveHistory',
  LumpSumPayoutHistory = 'LumpSumPayoutHistory',
  Member = 'Member',
  ParHistory = 'ParHistory',
  PaHistory = 'PaHistory',
  Person = 'Person',
  Pension = 'Pension',
  PensionChangeHistory = 'PensionChangeHistory',
  PensionPaymentHistory = 'PensionPaymentHistory',
  PensionEstimateHistory = 'PensionEstimateHistory',
  PensionStatementHistory = 'PensionStatementHistory',
  PlanInformation = 'Plan',
  PlanContact = 'PlanContact',
  PlanFinancialInstitution = 'PlanFinancialInstitution',
  PlanFormOfPayment = 'PlanFormOfPayment',
  PlanFundingHistory = 'PlanFundingHistory',
  PlanSlaRequirement = 'PlanSlaRequirement',
  PspaHistory = 'PspaHistory',
  PowerOfAttorney = 'PowerOfAttorney',
  ServiceHistory = 'ServiceHistory',
  SpouseHistory = 'SpouseHistory',
  StatusHistory = 'StatusHistory',
  T4AHistory = 'T4AHistory',
}

export enum PlanSections {
  PlanProfile = 'Plan Profile',
  PlanContact = 'Plan Contact',
  MemberConfiguration = 'Member Configuration',
  EmployerConfiguration = 'Employer Configuration',
  PensionConfiguration = 'Pension Configuration',
  CustomColumns = 'Custom Columns',
  CustomFields = 'Custom Fields',
}

export const PLAN_ADMINISTRATOR_ROLE_ID =
  '7f73ac7f-37b5-4642-a487-b461eff71de4';
