export const BREADCRUMBS = {
  PLANS: 'Plans',
  CREATE_NEW_PLAN: 'Create a New Plan',
  PLAN_MEMBERS: 'Plan Members',
  MEMBERS: 'Members',
  INFORMATION: 'Information',
  EMPLOYERS: 'Employers',
  PERSONAL: 'Personal',
  MEMBERSHIP: 'Membership',
  SPOUSE: 'Spouse',
  BENEFICIARIES: 'Beneficiaries',
  EARNINGS_AND_SERVICE: 'Earnings & Service',
  PENSION_BENEFITS: 'Pension Benefits',
  GROUP_BENEFITS: 'Group Benefits',
  PA_PSPA_PAR: 'PA/PSPA/PAR',
  COMMUNICATION: 'Communication',
  PAYOUTS: 'Payouts',
  TEMPLATES: 'Templates',
  ADD_TEMPLATE: 'Add Template',
  IMPORT_DATA: 'Import Data',
  REPORTS: 'Reports',
  DOCUMENTS: 'Documents',
  AUDIT_LOG: 'Audit log',
};
