import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Injectable } from '@angular/core';
import { PlanDraftSection } from '../services/types';
import { PlanService } from '../services/plan/plan.service';

@Injectable()
export class DraftResolver implements Resolve<PlanDraftSection> {
  constructor(private planService: PlanService) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.planService.getDraft(route.params['id']);
  }
}
