import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { BreadcrumbService } from '../../../services/breadcrumb/breadcrumb.service';
import { Breadcrumb } from '../../../types/breadcrumb';

@Component({
  selector: 'app-breadcrumb-navigation',
  templateUrl: './breadcrumb-navigation.component.html',
  styleUrls: ['./breadcrumb-navigation.component.scss'],
})
export class BreadcrumbNavigationComponent {
  public breadcrumbs$: Observable<Breadcrumb[]>;

  constructor(private readonly breadcrumbService: BreadcrumbService) {
    this.breadcrumbs$ = breadcrumbService.breadcrumbs$;
  }
}
