import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { PlanService } from '../../services/plan/plan.service';
import { PlanDraftSection } from '../../services/types';
import { PlanConfigurationSection } from '../../services/constants';

@Injectable()
export class PlanProfileResolver implements Resolve<PlanDraftSection> {
  constructor(private planService: PlanService) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.planService.getPlanSection(
      route.params['id'],
      PlanConfigurationSection.Profile,
    );
  }
}
