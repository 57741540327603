export const MAIN_CONTENT_EL_ID = 'appContent';
export const MONEY_DECIMAL_MARKER = '.';
export const MONEY_THOUSAND_SEPARATOR = ',';
export const CANADA_POSTAL_CODE_FORMAT = 'A1A 1A1';
export const CANADA_POSTAL_CODE_MASK = 'AAA AAA';
export const DEFAULT_TIME_FORMAT = 'hh:mmA';
export const DEFAULT_DATE_FORMAT = 'DD/MM/YYYY';
export const READ_ONLY_DATE_FORMAT = 'MMM D, y';
export const READ_ONLY_DATETIME_FORMAT = `${READ_ONLY_DATE_FORMAT} ${DEFAULT_TIME_FORMAT}`;
export const GET_DEFAULT_MIN_DATE_UTC = new Date(
  Date.UTC(1900, 0, 1, 0, 0, 0, 0),
);
export const GET_DEFAULT_MAX_DATE_UTC = new Date(
  Date.UTC(new Date().getFullYear() + 101, 0, 0, 0, 0, 0, 0),
);
export const MAX_DB_COLUMN_NAME_LENGTH = 128;
export const MAX_DEFAULT_TEXT_CHAR_LENGTH = 255;
export const MAX_DEFAULT_LONG_TEXT_CHAR_LENGTH = 4000;
export const MAX_INT_VALUE = 2147483647;
export const DEFAULT_DECIMALS_COUNT = 2;
export const IdentificationColumn = 'Id';
export const PAGE_SIZE_OPTIONS = [5, 10, 20];
export const DATE_FORMATS = {
  parse: {
    dateInput: [DEFAULT_DATE_FORMAT],
  },
  display: {
    dateInput: DEFAULT_DATE_FORMAT,
    monthYearLabel: 'MMM yyyy',
    dateA11yLabel: 'PP',
    monthYearA11yLabel: 'MMMM yyyy',
  },
};

export const DATETIME_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
    timeInput: 'HH:mm',
    datetimeInput: 'DD/MM/YYYY HH:mm',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    timeInput: 'HH:mm',
    datetimeInput: 'DD/MM/YYYY hh:mm A',
    monthYearLabel: 'MMM yyyy',
    dateA11yLabel: 'PP',
    monthYearA11yLabel: 'MMMM yyyy',
    popupHeaderDateLabel: 'MMM DD, ddd',
  },
};

export enum DataTypes {
  Boolean = 1,
  Int = 2,
  Enum = 3,
  Decimal = 4,
  Money = 5,
  Percent = 6,
  Date = 7,
  DateTime = 8,
  Text = 9,
  LongText = 10,
  Reference = 11,
  Year = 12,
  DataSizeFromBytes = 13,
  File = 14,
}

export enum FilterOperators {
  Equals = 'Equals',
}
