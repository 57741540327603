import { Injectable } from '@angular/core';
import {
  NavigationEnd,
  Router,
  Event,
  Data,
  ActivatedRoute,
  Params,
} from '@angular/router';
import { BehaviorSubject, filter, firstValueFrom, startWith, tap } from 'rxjs';
import { Breadcrumb } from '../../types/breadcrumb';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  private readonly _breadcrumbs$ = new BehaviorSubject<Breadcrumb[]>([]);
  readonly breadcrumbs$ = this._breadcrumbs$.asObservable();

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    this.router.events
      .pipe(
        filter((event: Event) => event instanceof NavigationEnd),
        startWith(this.activatedRoute),
        tap(() => this.setBreadcrumbs()),
      )
      .subscribe();
  }

  addBreadcrumb(breadcrumbs: Breadcrumb[], url: string, data?: Data): void {
    if (!data?.['breadcrumb']) return;
    breadcrumbs.push({ label: this.getLabel(data), url: url });
  }

  async setBreadcrumbs() {
    let breadcrumbs: Breadcrumb[] = [];
    let url = '';
    let route = this.activatedRoute.firstChild;
    while (route) {
      const params = await firstValueFrom(route.params);
      let path = route.routeConfig?.path;
      let data = route.routeConfig?.data;
      if (path && path.includes(':') && params) {
        path = this.getDynamicRouteParam(params, path);
        data = await firstValueFrom(route.data);
      }

      url += path ? '/' + path : '';
      this.addBreadcrumb(breadcrumbs, url, data);
      route = route.firstChild;
    }
    this._breadcrumbs$.next(breadcrumbs);
  }

  getDynamicRouteParam(params: Params, path: string) {
    const pathSegments = path.split('/');
    const processedSegments = pathSegments.map((pathSegment) => {
      const parameterName = pathSegment.split(':')[1];
      return params[parameterName] ?? pathSegment;
    });

    return processedSegments.join('/');
  }

  private getLabel(data: Data): string {
    // The breadcrumb can be defined as a static string or as a function to construct the breadcrumb element out of the route data
    return typeof data['breadcrumb'] === 'function'
      ? data['breadcrumb'](data)
      : data['breadcrumb'];
  }
}
