import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, first, map, Observable, switchMap } from 'rxjs';
import { MAIN_CONTENT_EL_ID } from './core/constants/global';
import { AuthService } from './services/auth/auth.service';
import { User } from './services/auth/types';
import { LayoutService } from './services/layout/layout.service';
import { EnumsService } from './shared/services/enums/enums.service';
import { PreloaderService } from './services/preloader/preloader.service';
import { MonitoringService } from '@epa-services/monitoring/monitoring.service';
import { Title } from '@angular/platform-browser';
import { PAGE_TITLE } from '@epa-ui/src/app/core/constants/titles';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewChecked {
  title = 'epa-ui';
  user$?: Observable<User | undefined>;
  redirecting = true;
  showMainNav$?: Observable<boolean>;
  showGlobalSpinner$?: Observable<boolean>;
  MAIN_CONTENT_EL_ID = MAIN_CONTENT_EL_ID;
  isOffline = false;

  constructor(
    private enums: EnumsService,
    private auth: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private layoutService: LayoutService,
    public preloaderService: PreloaderService,
    private cdRef: ChangeDetectorRef,
    private monitoringService: MonitoringService,
    private titleService: Title,
  ) {
    this.user$ = auth.user$.asObservable();
    this.showGlobalSpinner$ =
      this.preloaderService.showGlobalSpinner$.asObservable();
  }
  @HostListener('window:offline')
  setNetworkOffline(): void {
    this.isOffline = true;
  }

  @HostListener('window:online')
  setNetworkOnline(): void {
    this.isOffline = false;
  }

  ngOnInit() {
    this.auth.checkLogin().then();
    this.auth.msalRedirectEvent.subscribe((redirectCompleted) => {
      this.redirecting = !redirectCompleted;
    });

    this.showMainNav$ = this.layoutService.showMainNav$.asObservable();

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route: ActivatedRoute) => {
          while (route.firstChild) {
            route = route.firstChild;
          }

          this.layoutService.showMainNav$.next(
            !route.routeConfig?.data?.['hideMainNav'],
          );
          this.layoutService.addPagePadding$.next(
            !route.routeConfig?.data?.['removePagePadding'],
          );
          this.layoutService.showBreadcrumb$.next(
            !route.routeConfig?.data?.['hideBreadcrumb'],
          );
          this.layoutService.showPlanInformationHeader$.next(
            !route.routeConfig?.data?.['hidePlanInformationHeader'],
          );
          this.layoutService.showEditFormLayout$.next(
            route.routeConfig?.data?.['showEditFormLayout'],
          );

          this.setTitle(route);

          return route;
        }),
        switchMap((route: ActivatedRoute) => route.data),
      )
      .subscribe();
  }

  async setTitle(route: ActivatedRoute) {
    route.data.pipe(first()).subscribe((data) => {
      let title;

      switch (true) {
        case !!data['title']:
          title = this.getTitleFromData(data);
          break;
        case !!data['tableConfigs']:
          title = data['tableConfigs'][0].title;
          break;
        default:
          title = PAGE_TITLE.EPA;
          break;
      }

      this.titleService.setTitle(title);
    });
  }

  ngAfterViewChecked() {
    // For global spinner value change detection.
    this.cdRef.detectChanges();
  }

  private getTitleFromData(data: { [key: string]: any }) {
    const titleParam = data['title'];
    return typeof titleParam === 'function' ? titleParam(data) : titleParam;
  }
}
