export const ROUTING_PATHS = {
  PLANS: 'plans',
  ERRORS: 'errors',
  UNAUTHORIZED: 'unauthorized',
  NO_PERMISSIONS: 'no-permissions',
  UNHANDLED: 'unhandled',
  NOT_FOUND: 'not-found',
  DRAFTS: 'drafts',
  PLAN_NEW: 'new',
  PLAN_EDIT: 'edit',
  PLAN_FAVOURITES: 'favourites',
  PLAN_ALL_ASSIGNED_PLANS: 'all-assigned-plans',
  PLAN_PROFILE: 'plan-profile',
  PLAN_ONGOING_ADMIN: 'ongoing-admin',
  PLAN_WINDUP: 'wind-up',
  PLAN_MEMBER_CONFIGURATION: 'member-configuration',
  PLAN_EMPLOYER_CONFIGURATION: 'employer-configuration',
  PLAN_PENSION_CONFIGURATION: 'pension-configuration',
  PLAN_MEMBERS: 'members',
  PLAN_INFORMATION: 'information',
  PLAN_EMPLOYERS: 'employers',
  PLAN_REPORTS: 'reports',
  PLAN_TEMPLATES: 'templates',
  PLAN_MEMBER_PERSONAL: 'personal',
  PLAN_MEMBER_MEMBERSHIP: 'membership',
  PLAN_MEMBER_SPOUSE: 'spouse',
  PLAN_MEMBER_BENEFICIARIES: 'beneficiaries',
  PLAN_MEMBER_DOCUMENTS: 'documents',
  PLAN_USERS: 'users',
  EARNINGS_AND_SERVICE: 'earnings-and-service',
  PENSION_BENEFITS: 'pension-benefits',
  GROUP_BENEFITS: 'group-benefits',
  PLAN_MEMBER_PAYOUTS: 'payouts',
  PA_PSPA_PAR: 'pa-pspa-par',
  COMMUNICATION_LOG: 'communication-log',
  PLAN_IMPORT_TEMPLATES: 'add-template',
  PLAN_TEMPLATE_NEW: 'new',
  PLAN_TEMPLATES_IMPORT: 'import',
  PLAN_TEMPLATES_EXPORT: 'export',
  PLAN_TEMPLATES_IMPORT_DATA: 'import-data',
  PLAN_ADD_REPORT: 'add-report',
  PLAN_REPORTS_DRAFT: 'drafts',
  PLAN_REPORTS_FINAL: 'finals',
  PLAN_REPORT_NEW: 'new',
  AUDIT_LOG: 'audit-log',
};

export const TABLE_EDIT_ROUTING_PATHS = {
  PLAN: 'plan',
  PLAN_CONTACT: 'plan-contact',
  PLAN_FINANCIAL_INSTITUTION: 'plan-financial-institution',
  PLAN_FORM_OF_PAYMENT: 'plan-form-of-payment',
  PLAN_FUNDING_HISTORY: 'plan-funding-history',
  PLAN_SLA_REQUIREMENTS: 'plan-sla-requirements',
  EMPLOYER: 'employer',
  PERSON: 'person-details',
  MEMBER: 'member-details',
  BENEFICIARY: 'beneficiary',
  POWER_OF_ATTORNEY: 'power-of-attorney',
  SPOUSE_HISTORY: 'spouse-history',
  STATUS_HISTORY: 'status-history',
  EARNING_AND_CONTRIBUTION_HISTORY: 'earning-and-contribution-history',
  SERVICE_HISTORY: 'service-history',
  BUYBACK_HISTORY: 'buyback-history',
  BUYBACK_CONTRIBUTION_HISTORY: 'buyback-contribution-history',
  LEAVE_HISTORY: 'leave-history',
  GROUP_BENEFIT_HISTORY: 'group-benefit-history',
  PENSION: 'Pension',
  PENSION_CHANGE_HISTORY: 'pension-change-history',
  PENSION_PAYMENT_HISTORY: 'pension-payment-history',
  PENSION_ESTIMATE_HISTORY: 'pension-estimate-history',
  PENSION_STATEMENT_HISTORY: 'pension-statement-history',
  BENEFIT_ACCRUAL_HISTORY: 'benefit-accrual-history',
  DC_ACCOUNT_BALANCE_HISTORY: 'dc-account-balance-history',
  FLEX_BENEFIT_HISTORY: 'flex-benefit-history',
  CV_HISTORY: 'cv-history',
  LUMP_SUM_PAYOUT_HISTORY: 'lump-sum-payout-history',
  T4A_HISTORY: 't4a-history',
  PA_HISTORY: 'pa-history',
  PSPA_HISTORY: 'pspa-history',
  PAR_HISTORY: 'par-history',
};

export const ROUTING_MODULE_SEGMENTS = {
  PLANS: '/' + ROUTING_PATHS.PLANS,
  ERRORS: '/' + ROUTING_PATHS.ERRORS,
};

export const API_ROUTES = {
  ME: '/me',
  PERMISSIONS: '/admin/permissions',
  PLANS: '/plans',
  SET_FAVOURITE: '/set-favorite',
  PLAN_DRAFTS: '/plan-drafts',
  ENUMS: '/enums',
  PLAN_ROLES: '/plan-roles',
  USERS: '/users',
  COMMENTS: '/comments',
  IMPORT_TEMPLATES: '/import-templates',
  EXPORT_TEMPLATES: '/export-templates',
  IMPORT_TEMPLATE_TABLE_CONFIGS: '/import-templates/table-configs',
  EXPORT_TEMPLATE_TABLE_CONFIGS: '/export-templates/table-configs',
  COMMUNICATION_LOG: '/communication-log',
  REPORTS: '/reports',
  DOCUMENTS: '/documents',
  AUDIT_LOG: '/audit-log',
};

export const ERRORS_MODULE_ROUTING_SEGMENTS = {
  NO_PERMISSIONS: ROUTING_PATHS.ERRORS + '/' + ROUTING_PATHS.NO_PERMISSIONS,
  UNAUTHORIZED: ROUTING_PATHS.ERRORS + '/' + ROUTING_PATHS.UNAUTHORIZED,
  UNHANDLED: ROUTING_PATHS.ERRORS + '/' + ROUTING_PATHS.UNHANDLED,
  NOT_FOUND: ROUTING_PATHS.ERRORS + '/' + ROUTING_PATHS.NOT_FOUND,
};

export const PLANS_MODULE_ROUTING_SEGMENTS = {
  PLAN_TYPE: ROUTING_PATHS.PLANS + '/' + ROUTING_PATHS.PLAN_NEW,
  PLAN_NEW_ONGOING_ADMIN:
    ROUTING_PATHS.PLANS +
    '/' +
    ROUTING_PATHS.PLAN_NEW +
    '/' +
    ROUTING_PATHS.PLAN_ONGOING_ADMIN,
  PLAN_NEW_WINDUP:
    ROUTING_PATHS.PLANS +
    '/' +
    ROUTING_PATHS.PLAN_NEW +
    '/' +
    ROUTING_PATHS.PLAN_WINDUP,
};
