import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ItemResponseDto } from '../../shared/types/global';
import { User } from '../auth/types';
import { environment } from '../../../environments/environment';
import { API_ROUTES } from '../../../routing';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}

  getUserData() {
    return this.http
      .get<ItemResponseDto<User>>(`${environment.apiUrl}${API_ROUTES.ME}`)
      .pipe(map((data) => data.item));
  }
}
