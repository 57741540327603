import { Injectable } from '@angular/core';
import { BehaviorSubject, finalize, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PreloaderService {
  showGlobalSpinner$ = new BehaviorSubject<boolean>(false);
  private _loadersCount = 0;

  setPreloader<T>(): (source: Observable<T>) => Observable<T> {
    this.increaseCount();

    return (source) => source.pipe(finalize(() => this.decreaseCount()));
  }

  increaseCount() {
    this._loadersCount++;

    this.showGlobalSpinner$.next(true);
  }

  decreaseCount() {
    this._loadersCount--;

    if (this._loadersCount <= 0) this.showGlobalSpinner$.next(false);
  }
}
