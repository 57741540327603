import { Component, Input } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-preloader',
  templateUrl: './preloader.component.html',
  styleUrls: ['./preloader.component.scss'],
  standalone: true,
  imports: [MatProgressSpinnerModule, NgClass],
})
export class PreloaderComponent {
  @Input() diameter = 64;
  @Input() selfContained = true;
  @Input() fullScreen = false;
}
