<div class="error">
    <div class="error__content">
        <h1 class="error__notice">
            The page you are looking for cannot be found.
        </h1>
        <p class="error__description">
            Please contact
            <a epa-button theme="link" href="mailto: {{ SUPPORT_EMAIL }}">{{
                SUPPORT_EMAIL
            }}</a>
            or head back to homepage. (Error 404)
        </p>
        <button epa-button theme="primary" (click)="goHome()">
            Go to homepage
        </button>
    </div>
</div>
