import { Component, Inject } from '@angular/core';
import { ToastData, ToastTypes } from './types';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { NotificationToastService } from '../../services/notification-toast/notification-toast.service';

@Component({
  selector: 'app-notification-toast',
  templateUrl: './notification-toast.component.html',
  styleUrls: ['./notification-toast.component.scss'],
})
export class NotificationToastComponent {
  availableToastTypes = ToastTypes;
  toastData: ToastData;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: ToastData,
    private notificationToastService: NotificationToastService,
  ) {
    this.toastData = data;
  }

  closeSnackBar() {
    this.notificationToastService.hideToast();
  }
}
