<section class="main-nav">
    <ng-container *ngIf="user$ | async as user; else userInfoPlaceholder">
        <div class="main-nav__user">
            <ng-container *ngIf="user.image; else avatarPlaceholder">
                <img
                    [src]="imageUrl"
                    alt="User"
                    class="main-nav__user-logo"
                    data-testId="user-img"
                />
            </ng-container>
            <ng-template #avatarPlaceholder>
                <div
                    class="main-nav__user-logo main-nav__user-logo--placeholder"
                    data-testId="user-img-placeholder"
                >
                    <svg-icon
                        [svgStyle]="{ 'width.px': 24 }"
                        src="/assets/icons/nav/avatar_placeholder.svg"
                    ></svg-icon>
                </div>
            </ng-template>
            <div class="main-nav__user-name">
                <p data-testId="user-name">{{ user.name }}</p>
            </div>
        </div>
    </ng-container>

    <ng-template #userInfoPlaceholder>
        <div></div>
    </ng-template>

    <nav class="main-nav__items">
        <ng-container *ngIf="user$ | async as user">
            <app-main-nav-item
                [link]="ROUTING_PATHS.PLANS"
                [markExact]="false"
                iconName="folder"
                text="Plans"
            ></app-main-nav-item>
            <div
                *ngIf="planInformation$ | async as planInfo"
                class="main-nav__items main-nav__items--level-2"
            >
                <div
                    title="{{ planInfo.name }}"
                    class="main-nav__item main-nav__item--label"
                >
                    <p>{{ planInfo.name | uppercase }}</p>
                </div>
                <app-main-nav-item
                    [link]="
                        ROUTING_PATHS.PLANS +
                        '/' +
                        planInfo.id +
                        '/' +
                        ROUTING_PATHS.PLAN_MEMBERS
                    "
                    iconName="person"
                    text="Members"
                    *appNavItemPermission="{
                        allowedPermissions: [
                            PERMISSIONS.DataView,
                            PERMISSIONS.DataEdit,
                            PERMISSIONS.RestrictedDataEdit
                        ],
                        assignedPermissions: planInfo.assignedPermissions
                    }"
                >
                </app-main-nav-item>
                <app-main-nav-item
                    [link]="
                        ROUTING_PATHS.PLANS +
                        '/' +
                        planInfo.id +
                        '/' +
                        ROUTING_PATHS.PLAN_EMPLOYERS
                    "
                    iconName="employer"
                    text="Employers"
                    *appNavItemPermission="{
                        allowedPermissions: [
                            PERMISSIONS.DataView,
                            PERMISSIONS.DataEdit,
                            PERMISSIONS.RestrictedDataEdit
                        ],
                        assignedPermissions: planInfo.assignedPermissions
                    }"
                ></app-main-nav-item>
                <app-main-nav-item
                    [link]="
                        ROUTING_PATHS.PLANS +
                        '/' +
                        planInfo.id +
                        '/' +
                        ROUTING_PATHS.PLAN_REPORTS
                    "
                    [markExact]="false"
                    iconName="reports"
                    text="Reports"
                    *appNavItemPermission="{
                        allowedPermissions: [
                            PERMISSIONS.ReportCreate,
                            PERMISSIONS.ReportExecute
                        ],
                        assignedPermissions: planInfo.assignedPermissions
                    }"
                ></app-main-nav-item>
                <app-main-nav-item
                    [link]="
                        ROUTING_PATHS.PLANS +
                        '/' +
                        planInfo.id +
                        '/' +
                        ROUTING_PATHS.PLAN_TEMPLATES
                    "
                    [markExact]="false"
                    iconName="document"
                    text="Templates"
                    *appNavItemPermission="{
                        allowedPermissions: [
                            PERMISSIONS.ImportCreate,
                            PERMISSIONS.ExportCreate,
                            PERMISSIONS.ImportExecute,
                            PERMISSIONS.ExportExecute,
                            PERMISSIONS.ExportRestrictedExecute
                        ],
                        assignedPermissions: planInfo.assignedPermissions
                    }"
                ></app-main-nav-item>
                <app-main-nav-item
                    [link]="
                        ROUTING_PATHS.PLANS +
                        '/' +
                        planInfo.id +
                        '/' +
                        ROUTING_PATHS.AUDIT_LOG
                    "
                    [markExact]="false"
                    iconName="audit"
                    text="Audit log"
                    *appNavItemPermission="{
                        allowedPermissions: [PERMISSIONS.AuditLogExport],
                        assignedPermissions: planInfo.assignedPermissions
                    }"
                ></app-main-nav-item>
                <app-main-nav-item
                    [link]="
                        ROUTING_PATHS.PLANS +
                        '/' +
                        planInfo.id +
                        '/' +
                        ROUTING_PATHS.PLAN_INFORMATION
                    "
                    [markExact]="false"
                    iconName="info"
                    text="Information"
                    *appNavItemPermission="{
                        allowedPermissions: [
                            PERMISSIONS.DataView,
                            PERMISSIONS.DataEdit,
                            PERMISSIONS.RestrictedDataEdit
                        ],
                        assignedPermissions: planInfo.assignedPermissions
                    }"
                >
                </app-main-nav-item>
            </div>
        </ng-container>
    </nav>

    <div class="main-nav__log-out-wrapper">
        <button
            (click)="logout()"
            class="main-nav__log-out main-nav__item"
            data-testId="logout-button"
        >
            <svg-icon
                [svgStyle]="{ 'width.px': 24 }"
                src="/assets/icons/nav/logout.svg"
            >
            </svg-icon>
            <p>Log out</p>
        </button>
    </div>

    <div class="main-nav__logo">
        <svg-icon src="/assets/icons/nav/logo.svg"></svg-icon>
    </div>
</section>
