import { Pipe, PipeTransform } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DEFAULT_DATE_FORMAT } from '../../core/constants/global';

@Pipe({
  name: 'dateFormat',
  standalone: true,
})
export class DateFormatPipe implements PipeTransform {
  constructor(private dateAdapter: MomentDateAdapter) {}
  transform(
    value: Date | string,
    format: string = DEFAULT_DATE_FORMAT,
    applyOffset = false,
  ): string | undefined {
    value = new Date(value);

    const offset = applyOffset ? value.getTimezoneOffset() * -1 : 0;

    return this.dateAdapter
      .parse(value, format)
      ?.utcOffset(offset)
      ?.format(format);
  }
}
