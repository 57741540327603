import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { API_ROUTES } from '../../../../routing';
import { Enum, EnumsResponse } from './types';
import { AvailableEnums, EnumStatus } from './constants';
import { BehaviorSubject, first, map, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EnumsService {
  allEnumObservables: {
    [key: string]: { status: EnumStatus; data$: BehaviorSubject<Enum[]> };
  } = {};

  constructor(private http: HttpClient) {
    Object.keys(AvailableEnums).forEach((key) => {
      this.allEnumObservables[key] = {
        status: EnumStatus.NotFetched,
        data$: new BehaviorSubject<Enum[]>([]),
      };
    });
  }

  getEnum(enumName: AvailableEnums) {
    return this.http
      .get<EnumsResponse>(`${environment.apiUrl}${API_ROUTES.ENUMS}`, {
        params: {
          enums: [enumName],
        },
      })
      .pipe(map((data) => data.dictionary[enumName]));
  }

  getStoredEnum(enumName: AvailableEnums) {
    const enumObj = this.allEnumObservables[enumName];

    if (enumObj.status === EnumStatus.NotFetched) {
      enumObj.status = EnumStatus.Fetching;
      this.getEnum(enumName)
        .pipe(
          first(),
          tap((data) => {
            this.storeEnum(enumName, data);
            enumObj.status = EnumStatus.Fetched;
          }),
        )
        .subscribe();
    }

    return enumObj.data$.asObservable();
  }

  storeEnum(enumName: AvailableEnums, data: Enum[]) {
    this.allEnumObservables[enumName].data$.next(data);
  }
}
