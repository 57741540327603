import { Injectable } from '@angular/core';
import { PlanMainForm, PlanMainInformation } from '../types';
import { FormBuilder, FormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class PlanFormService {
  formGroup = this.fb.group<PlanMainForm>({
    id: new FormControl(),
    name: new FormControl(),
    planState: new FormControl(),
    accessLevel: new FormControl(),
    availableTables: new FormControl([]),
    assignedPermissions: new FormControl([]),
  });
  plan$: BehaviorSubject<PlanMainInformation | undefined> = new BehaviorSubject<
    PlanMainInformation | undefined
  >(undefined);
  isPlanDirty = false;

  constructor(private fb: FormBuilder) {}

  setMainFormData(data: PlanMainInformation) {
    this.formGroup.patchValue(data);
    this.plan$.next(data);
    this.formGroup.markAsPristine();
  }
}
