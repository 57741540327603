<div
    [ngClass]="[
        (addPagePadding$ | async) ? '' : 'page--no-padding',
        (showBreadcrumb$ | async) ? '' : 'page--full'
    ]"
    class="page"
>
    <div *ngIf="showBreadcrumb$ | async" class="page__breadcrumbs">
        <app-breadcrumb-navigation></app-breadcrumb-navigation>
    </div>
    <div class="page__content">
        <router-outlet></router-outlet>
    </div>
</div>
