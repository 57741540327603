import {
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Permission } from '../../shared/utils/permissions';

@Directive({
  standalone: true,
  selector: '[appNavItemPermission]',
})
export class NavItemPermissionDirective implements OnInit {
  @Input() appNavItemPermission: {
    allowedPermissions: Permission[];
    assignedPermissions: Permission[];
  } = { allowedPermissions: [], assignedPermissions: [] };

  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
  ) {}

  ngOnInit() {
    const allowed = this.appNavItemPermission.allowedPermissions.some((p) =>
      this.appNavItemPermission.assignedPermissions.includes(p),
    );

    if (allowed) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainerRef.clear();
    }
  }
}
