import { ErrorHandler, Injectable } from '@angular/core';
import { IExceptionTelemetry } from '@microsoft/applicationinsights-common';
import { MonitoringService } from '../services/monitoring/monitoring.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private monitoringService: MonitoringService) {}

  handleError(error: Error): void {
    this.monitoringService.trackException(<IExceptionTelemetry>{
      exception: error,
    });
    throw error;
  }
}
