import { Injectable } from '@angular/core';
import { environment } from '@epa-ui/src/environments/environment';
import {
  ApplicationInsights,
  IExceptionTelemetry,
} from '@microsoft/applicationinsights-web';

const uriApiPattern = /^(GET|POST|PUT|DELETE) api/i;

@Injectable({
  providedIn: 'root',
})
export class MonitoringService {
  private applicationInsights?: ApplicationInsights;
  constructor() {
    if (environment.appInsightsKey) {
    }
    this.initAppInsights();
  }

  setUserContext(email: string) {
    this.applicationInsights?.setAuthenticatedUserContext(email);
  }

  clearUserId() {
    this.applicationInsights?.clearAuthenticatedUserContext();
  }

  public trackException(exception: IExceptionTelemetry): void {
    if (this.applicationInsights) {
      this.applicationInsights.appInsights.trackException(exception);
    }
  }

  private initAppInsights(): void {
    if (!environment?.appInsightsKey) return;

    this.applicationInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.appInsightsKey,
        disableFetchTracking: false,
        disableExceptionTracking: false,
        enableCorsCorrelation: false,
        enableAutoRouteTracking: true,
      },
    });
    this.applicationInsights.loadAppInsights();
    this.applicationInsights.trackPageView();
    this.applicationInsights.addTelemetryInitializer((item) => {
      if (item.baseType === 'RemoteDependencyData') {
        const name = item.baseData?.['name'];
        if (!name) {
          return true;
        }
        return uriApiPattern.test(name);
      }
      return true;
    });
  }
}
