<div class="notification-toast">
    <div class="notification-toast__content">
        <ng-container [ngSwitch]="toastData.type">
            <ng-container *ngSwitchCase="availableToastTypes.Info">
                <svg-icon
                    [svgStyle]="{ 'width.px': 24 }"
                    class="notification-toast__icon"
                    src="assets/icons/notification-info.svg"
                ></svg-icon>
                {{ toastData.content ?? 'Information' }}
            </ng-container>
            <ng-container *ngSwitchCase="availableToastTypes.Warning">
                <svg-icon
                    [svgStyle]="{ 'width.px': 24 }"
                    class="notification-toast__icon"
                    src="assets/icons/notification-warning.svg"
                ></svg-icon>
                {{ toastData.content ?? 'Warning that must be dismissed' }}
            </ng-container>
            <ng-container *ngSwitchCase="availableToastTypes.Error">
                <svg-icon
                    [svgStyle]="{ 'width.px': 24 }"
                    class="notification-toast__icon"
                    src="assets/icons/notification-error.svg"
                ></svg-icon>
                {{
                    toastData.content ??
                        'There was an error completing your request'
                }}
            </ng-container>
            <ng-container *ngSwitchCase="availableToastTypes.Success">
                <svg-icon
                    [svgStyle]="{ 'width.px': 24 }"
                    class="notification-toast__icon"
                    src="assets/icons/notification-check-circle.svg"
                ></svg-icon>
                {{
                    toastData.content ??
                        'Your request was successfully completed'
                }}
            </ng-container>
        </ng-container>
    </div>
    <ng-container *ngIf="toastData.needsAction || toastData.showClose">
        <button (click)="closeSnackBar()">
            <svg-icon
                [svgStyle]="{ 'width.px': 24 }"
                class="notification-toast__icon"
                src="assets/icons/close.svg"
            ></svg-icon>
        </button>
    </ng-container>
</div>
