import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { PlanMainInformation } from '../services/types';
import { PlanService } from '../services/plan/plan.service';
import { PlanFormService } from '../services/plan/plan-form.service';

@Injectable()
export class PlanResolver implements Resolve<PlanMainInformation> {
  constructor(
    private planService: PlanService,
    private planFormService: PlanFormService,
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    const plan = this.planFormService.plan$.value;

    if (plan !== undefined && plan?.id === route.params['id']) return plan;

    return this.planService.getPlan(route.params['id']);
  }
}
