<ol class="breadcrumb-navigation">
    <li
        *ngFor="let breadcrumb of breadcrumbs$ | async"
        class="breadcrumb-navigation__item"
    >
        <a
            [routerLinkActiveOptions]="{ exact: true }"
            [routerLink]="breadcrumb.url"
            routerLinkActive="breadcrumb-navigation__item--active"
        >
            {{ breadcrumb.label }}
        </a>
    </li>
</ol>
