<a
    #rla="routerLinkActive"
    [routerLink]="link"
    class="main-nav__item"
    routerLinkActive="main-nav__item--active"
    [routerLinkActiveOptions]="{ exact: markExact }"
>
    <ng-container
        *ngIf="iconName"
        [ngTemplateOutletContext]="{ iconName }"
        [ngTemplateOutlet]="rla.isActive ? iconFilled : iconOutlined"
    >
    </ng-container>
    {{ text }}
</a>

<ng-template #iconOutlined let-iconName="iconName">
    <svg-icon
        [src]="'/assets/icons/nav/' + iconName + '_outlined.svg'"
        [svgStyle]="{ 'width.px': 24 }"
    ></svg-icon>
</ng-template>
<ng-template #iconFilled let-iconName="iconName">
    <svg-icon
        [src]="'/assets/icons/nav/' + iconName + '_filled.svg'"
        [svgStyle]="{ 'width.px': 24 }"
    ></svg-icon>
</ng-template>
