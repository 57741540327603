const permissionNames = [
  'UserAdmin',
  'PlanConfigCreate',
  'PlanConfigView',
  'PlanConfigEdit',
  'PlanInfoEdit',
  'DataView',
  'DataEdit',
  'RestrictedDataEdit',
  'DataDelete',
  'ImportCreate',
  'ExportCreate',
  'ImportExecute',
  'ExportExecute',
  'ExportRestrictedExecute',
  'CommunicationLogView',
  'CommunicationLogCreate',
  'CommunicationLogEdit',
  'ReportCreate',
  'ReportExecute',
  'DocumentView',
  'DocumentUpload',
  'DocumentDelete',
  'AuditLogExport',
] as const;
export type Permission = (typeof permissionNames)[number];

export const PERMISSIONS: {
  [K in Permission]: Permission;
} = {
  UserAdmin: 'UserAdmin',
  PlanConfigCreate: 'PlanConfigCreate',
  PlanConfigView: 'PlanConfigView',
  PlanConfigEdit: 'PlanConfigEdit',
  PlanInfoEdit: 'PlanInfoEdit',
  DataView: 'DataView',
  DataEdit: 'DataEdit',
  RestrictedDataEdit: 'RestrictedDataEdit',
  DataDelete: 'DataDelete',
  ImportCreate: 'ImportCreate',
  ExportCreate: 'ExportCreate',
  ImportExecute: 'ImportExecute',
  ExportExecute: 'ExportExecute',
  ExportRestrictedExecute: 'ExportRestrictedExecute',
  CommunicationLogView: 'CommunicationLogView',
  CommunicationLogCreate: 'CommunicationLogCreate',
  CommunicationLogEdit: 'CommunicationLogEdit',
  ReportCreate: 'ReportCreate',
  ReportExecute: 'ReportExecute',
  DocumentView: 'DocumentView',
  DocumentUpload: 'DocumentUpload',
  DocumentDelete: 'DocumentDelete',
  AuditLogExport: 'AuditLogExport',
};
